import { FC, ReactNode } from "react";
import classNames from "classnames";
import Link from "next/link";
import styles from "./NavLink.module.scss";
import Arrow from "./arrow.svg";

export type Props = {
  title: string;
  href: string;
  selected?: boolean;
  arrow?: boolean;
  handleMouseEnter?: () => void;
  addclasses?: string[];
};

const NavLink: FC<Props> = ({
  title,
  href,
  selected,
  arrow,
  handleMouseEnter,
  addclasses,
}) => {
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    { [styles["selected"]]: selected }
  );

  const arrowClass = classNames([styles["link-arrow"]], {
    [styles["hide"]]: !arrow,
  });
  return (
    <Link className={className} href={href} onMouseEnter={handleMouseEnter}>
      {title}
      <Arrow className={arrowClass} />
    </Link>
  );
};

export default NavLink;
