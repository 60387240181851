import { FC } from "react";
import styles from "./PhoneField.module.scss";
import classNames from "classnames";
import Input from "react-phone-number-input/input";

export type Props = {
  id: string;
  value?: string;
  name?: string;
  error?: boolean | string;
  handleChange: (val: string) => void;
  handleBlur?: any;
  addclasses?: string[];
};

const PhoneField: FC<Props> = ({
  id,
  value,
  name,
  error,
  handleChange,
  handleBlur,
  addclasses,
}) => {
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const inputClass = classNames([styles["textfield"]], {
    [styles["error"]]: error,
  });

  return (
    <div className={className}>
      <Input
        id={id}
        name={name || id}
        value={value}
        className={inputClass}
        onChange={(num) => {
          handleChange(num || "_");
        }}
        onBlur={handleBlur}
        international
        withCountryCallingCode
        country="RU"
        autoComplete="off"
        smartCaret={false}
      />
    </div>
  );
};

export default PhoneField;
