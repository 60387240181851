import { StaticImageData } from "next/image";

import { SDK, sdk_list } from "../data/sdk";

import FaceIcon from "../../public/imgs/icons/face_icon.svg?url";
import MinDigits from "../../public/imgs/icons/mindigits.svg?url";
import NistIcon from "../../public/imgs/icons/nist_icon.svg?url";
import FaceRecImg from "../../public/imgs/products/product-01-big.png";
import FaceRecPreview from "../../public/imgs/products/product-01-small.png";
import SilhouetteImg from "../../public/imgs/products/product-02-big.png";
import SilhouettePreview from "../../public/imgs/products/product-02-small.png";
import VideoanalyticsImg from "../../public/imgs/products/product-03-big.png";
import VideoanalyticsPreview from "../../public/imgs/products/product-03-small.png";
import VehicleImg from "../../public/imgs/products/product-04-big.png";
import VehiclePreview from "../../public/imgs/products/product-04-small.png";
import GoodsImg from "../../public/imgs/products/product-05-big.png";
import GoodsPreview from "../../public/imgs/products/product-05-small.png";
import DocsImg from "../../public/imgs/products/product-06-big.png";
import DocsPreview from "../../public/imgs/products/product-06-small.png";
import SDK_1_Img from "../../public/imgs/sdk/face-sdk.png";
import SDK_2_Img from "../../public/imgs/sdk/facecapture-sdk.png";

export type ProductFeaturesCard = {
  title: string;
  features: string[];
};

type Demonstration = {
  title: string;
  description: string;
  imgSrc: StaticImageData;
};

export type Achivement = {
  name: string;
  imgSrc: StaticImageData;
};

export type Product = {
  id: number;
  abstractImg: StaticImageData;
  previewImg: StaticImageData;
  sdk_list: SDK[];
  features: string;
  features_cards: ProductFeaturesCard[];
  demonstration_list: Demonstration[];
  href: string;
  achivements?: Achivement[];
};

const feature_card_1: ProductFeaturesCard = {
  title: "functionality_in_one",
  features: [
    "face_detect",
    "face_track",
    "face_assestment",
    "bio_temp",
    "demography",
    "liveness",
    "mask_on",
    "add_attr",
  ],
};
const feature_card_2: ProductFeaturesCard = {
  title: "cross_platform",
  features: ["support", "api_integration", "hardware", "installers"],
};
const feature_card_3: ProductFeaturesCard = {
  title: "maintenance",
  features: ["full_support"],
};
const feature_card_4: ProductFeaturesCard = {
  title: "supported_functions",
  features: ["min_size", "crowd", "people_count", "heatmaps", "search_by"],
};
const feature_card_5: ProductFeaturesCard = {
  title: "supported_functions",
  features: [
    "signs",
    "grz",
    "grz_recognition",
    "assess_grz",
    "classify_grz",
    "classify_grz_country",
    "detect_ts",
    "detect_ts_type",
    "special_vehicle",
    "countries",
  ],
};
const feature_card_6: ProductFeaturesCard = {
  title: "supported_functions",
  features: ["vietnam_passport", "doc_recognition"],
};
const feature_card_7: ProductFeaturesCard = {
  title: "cross_platform",
  features: [
    "support_v2",
    "api_integration_v2",
    "hardware_v2",
    "distributions",
  ],
};
const feature_card_8: ProductFeaturesCard = {
  title: "cross_platform",
  features: [
    "support_v2",
    "api_integration_v3",
    "hardware_v3",
    "distributions_v2",
  ],
};
const feature_card_9: ProductFeaturesCard = {
  title: "cross_platform",
  features: [
    "support_v2",
    "api_integration_v4",
    "hardware_v2",
    "distributions",
  ],
};
const feature_card_10: ProductFeaturesCard = {
  title: "cross_platform",
  features: ["support_v2", "api_integration_v2", "hardware_v2", "installers"],
};
const feature_card_11: ProductFeaturesCard = {
  title: "supported_functions",
  features: [
    "product_detection",
    "SKU_identification",
    "perfect_store",
    "price_tag_detection",
  ],
};

const {
  FaceSDK,
  FaceCaptureSDK,
  FaceCloudSDK,
  PersonDetectSDK,
  CrowdEstimationSDK,
  LprSDK,
  VehicleRecognitionSDK,
  DocSDK,
  AbandonedObjectsDetector,
  PeopleTrackingSDK,
  PeopleCounter,
  ShelfRecognition,
  PosAnalytics,
} = sdk_list;

const demonstration_1: Demonstration = {
  title: "Lorem Ipsum",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  imgSrc: SDK_1_Img,
};
const demonstration_2: Demonstration = {
  title: "Lorem Ipsum",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  imgSrc: SDK_2_Img,
};

const product_1: Product = {
  id: 1,
  abstractImg: FaceRecImg,
  previewImg: FaceRecPreview,
  sdk_list: [FaceSDK, FaceCaptureSDK, FaceCloudSDK],
  features: "easy",
  features_cards: [feature_card_1, feature_card_2, feature_card_3],
  demonstration_list: [demonstration_1, demonstration_2],
  achivements: [
    { name: "rus_po_reestr", imgSrc: MinDigits },
    { name: "uni_bio_system", imgSrc: FaceIcon },
    { name: "nist", imgSrc: NistIcon },
  ],
  href: "face-recognition",
};

const product_2: Product = {
  id: 2,
  abstractImg: SilhouetteImg,
  previewImg: SilhouettePreview,
  sdk_list: [PersonDetectSDK, PeopleTrackingSDK, PeopleCounter],
  features:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  features_cards: [feature_card_4, feature_card_7, feature_card_3],
  demonstration_list: [demonstration_1, demonstration_2],
  href: "silhouette-recognition",
};
const product_3: Product = {
  id: 3,
  abstractImg: VehicleImg,
  previewImg: VehiclePreview,
  sdk_list: [LprSDK, VehicleRecognitionSDK],
  features:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  features_cards: [feature_card_5, feature_card_8, feature_card_3],
  demonstration_list: [demonstration_1, demonstration_2],
  href: "transport-recognition",
};
const product_4: Product = {
  id: 4,
  abstractImg: DocsImg,
  previewImg: DocsPreview,
  sdk_list: [DocSDK],
  features:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  features_cards: [feature_card_6, feature_card_9, feature_card_3],
  demonstration_list: [demonstration_1, demonstration_2],
  href: "document-recognition",
};
const product_5: Product = {
  id: 5,
  abstractImg: VideoanalyticsImg,
  previewImg: VideoanalyticsPreview,
  sdk_list: [CrowdEstimationSDK],
  features:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  features_cards: [feature_card_10, feature_card_3],
  demonstration_list: [demonstration_1, demonstration_2],
  href: "situational-video-analytics",
};
const product_6: Product = {
  id: 6,
  abstractImg: GoodsImg,
  previewImg: GoodsPreview,
  sdk_list: [ShelfRecognition],
  features:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  features_cards: [feature_card_11, feature_card_3],
  demonstration_list: [demonstration_1, demonstration_2],
  href: "product-recognition",
};

const products_list: { [key: string]: Product } = {
  "face-recognition": product_1,
  "silhouette-recognition": product_2,
  "transport-recognition": product_3,
  "document-recognition": product_4,
  "situational-video-analytics": product_5,
  "product-recognition": product_6,
};

export const products: Product[] = [
  product_1,
  product_2,
  product_3,
  product_4,
  product_5,
  product_6,
];

export const getProductByName = (p_name: string) => {
  return products_list[p_name];
};
