import { FC } from "react";
import styles from "./Logo.module.scss";
import classNames from "classnames";
import Tevian from "./tevian.svg";

export type Props = { handleClick?: () => void; addclasses?: string[] };

const Logo: FC<Props> = ({ handleClick, addclasses }) => {
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );
  return (
    <div className={className} onClick={handleClick}>
      <Tevian />
    </div>
  );
};

export default Logo;
