import {
  FC,
  HTMLProps,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./Sidebar.module.scss";
import classNames from "classnames";
import GridContainer from "../../GridContainer/GridContainer";
import Submenu from "./Submenu";
import { products } from "../../../assets/data/products";
import { useTranslation } from "next-i18next";
import { solutions } from "../../../assets/data/solutions";
import OutlinedButton from "../../Buttons/OutlinedButton/OutlinedButton";
import { gsap } from "gsap";
import { useAppProvider } from "../../../utils/appProvider";
import { colors, screenSizes } from "../../../utils/constants";
import Link from "next/link";
import { useRouter } from "next/router";

gsap.config({ nullTargetWarn: false });

export type Props = {
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Sidebar: FC<Props> = (props) => {
  const tl = useRef<gsap.core.Timeline>();
  const { addclasses } = props;
  const { sidebarOpen, setSidebarOpen, setSendMessageOpen } = useAppProvider();
  const router = useRouter();
  const route = router.asPath;
  const { t } = useTranslation(["products", "common"]);
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const tintClass = classNames([styles["tint"]], {
    [styles["tint_show"]]: sidebarOpen,
  });
  const mm = gsap.matchMedia();

  const [submenuOpened, setSubmenuOpened] = useState<number | null>(null);

  const goTo = (e: any, href: string) => {
    e.stopPropagation();
    router.push(href).then(() => {
      setSidebarOpen(false);
      document.querySelector("html")!.style.overflowY = "overlay";
    });
  };

  const handleSubmenuClick = (id: number) => {
    setSubmenuOpened((prev) => (prev === id ? null : id));
  };

  const handleSendMessageClick = () => {
    setSendMessageOpen(true);
    document.querySelector("html")!.style.overflowY = "hidden";
  };

  const registerPhoneCopy = () => {
    (window as any).ym(
      process.env.NEXT_PUBLIC_YANDEX_METRIC_ID,
      "reachGoal",
      "phone_copy"
    );
  };

  useLayoutEffect(() => {
    const menuRef = `.${styles["container"]}`;
    const wrapperRef = `.${styles["wrapper"]}`;
    mm.add(
      {
        ...screenSizes,
        isBig: "(min-width: 1025px)",
        isSmall: "(max-width: 600px)",
      },
      (context) => {
        let { isSmall, isBig } = context.conditions as any;
        tl.current = gsap.timeline({ paused: true });
        if (isBig) {
          document.querySelector("html")!.style.overflowY = "overlay";
          return setSidebarOpen(false);
        }
        tl.current.set(wrapperRef, { zIndex: 100, opacity: 1 });
        tl.current.to(menuRef, {
          x: isSmall ? "-100%" : "-40%",
          duration: 0.5,
        });
      }
    );
  }, []);

  useEffect(() => {
    sidebarOpen ? tl.current?.play() : tl.current?.reverse();
  }, [sidebarOpen]);

  useEffect(() => {
    if (route == "/") {
      return setSubmenuOpened(null);
    }
    const [_, submenu] = route.split("/");
    switch (submenu) {
      case "product": {
        return setSubmenuOpened(1);
      }
      case "solution": {
        return setSubmenuOpened(2);
      }
      case "about": {
        return setSubmenuOpened(3);
      }
      case "careers": {
        return setSubmenuOpened(3);
      }
    }
  }, [route]);

  return (
    <div className={styles["wrapper"]}>
      <GridContainer addclasses={[className]}>
        <div className={styles["menu"]}>
          <Submenu
            title={t("common:products")}
            opened={submenuOpened === 1 ? 1 : 0}
            onClick={(e) => {
              handleSubmenuClick(1);
            }}
          >
            <div
              onClick={(e) => goTo(e, "/product")}
              className={styles["sublink"]}
              style={{
                color:
                  router.asPath === "/product" ? colors.blue : colors.black,
              }}
            >
              {t("common:to_the_section")}
            </div>
            {products.map((p) => {
              return (
                <div
                  key={p.href}
                  onClick={(e) => goTo(e, `/product/${p.href}`)}
                  className={styles["sublink"]}
                  style={{
                    color:
                      router.asPath === `/product/${p.href}`
                        ? colors.blue
                        : colors.black,
                  }}
                >
                  {t(`products:name.${p.href}`)}
                </div>
              );
            })}
          </Submenu>
          <Submenu
            title={t("common:solutions")}
            opened={submenuOpened === 2 ? 1 : 0}
            onClick={() => {
              handleSubmenuClick(2);
            }}
          >
            <div
              onClick={(e) => goTo(e, "/solution")}
              className={styles["sublink"]}
              style={{
                color:
                  router.asPath === "/solution" ? colors.blue : colors.black,
              }}
            >
              {t("common:to_the_section")}
            </div>
            {solutions.map((s) => {
              return (
                <div
                  key={s.href}
                  className={styles["sublink"]}
                  onClick={(e) => goTo(e, `/solution/${s.href}`)}
                  style={{
                    color:
                      router.asPath === `/solution/${s.href}`
                        ? colors.blue
                        : colors.black,
                  }}
                >
                  {t(`solutions:name.${s.href}`)}
                </div>
              );
            })}
            <div
              className={styles["sublink"]}
              onClick={(e) => goTo(e, "/customer")}
              style={{
                color:
                  router.asPath === "/customer" ? colors.blue : colors.black,
              }}
            >
              {t("common:customer")}
            </div>
          </Submenu>
          <div
            className={styles["link"]}
            onClick={(e) => goTo(e, "/partners")}
            style={{
              color: router.asPath === "/partners" ? colors.blue : colors.black,
            }}
          >
            {t("common:for_partners")}
          </div>
          <Submenu
            title={t("common:about_us")}
            opened={submenuOpened === 3 ? 1 : 0}
            onClick={() => {
              handleSubmenuClick(3);
            }}
          >
            <div
              onClick={(e) => goTo(e, "/about")}
              className={styles["sublink"]}
              style={{
                color: router.asPath === "/about" ? colors.blue : colors.black,
              }}
            >
              {t("common:to_the_section")}
            </div>
            <div
              key={"career"}
              onClick={(e) => goTo(e, "/careers")}
              className={styles["sublink"]}
              style={{
                color:
                  router.asPath === "/careers" ? colors.blue : colors.black,
              }}
            >
              {t(`common:career`)}
            </div>
          </Submenu>
          <div
            className={styles["link"]}
            onClick={(e) => goTo(e, "/contacts")}
            style={{
              color: router.asPath === "/contacts" ? colors.blue : colors.black,
            }}
          >
            {t("common:contacts")}
          </div>
        </div>
        <a
          href="tel:+7(917)531-86-41"
          className={styles["phone"]}
          onCopy={registerPhoneCopy}
        >
          +7 (917) 531-86-41
        </a>
        <OutlinedButton
          title={t("common:discuss_issue")}
          addclasses={[styles["btn"]]}
          handleClick={handleSendMessageClick}
        />
      </GridContainer>
      <div className={tintClass}></div>
    </div>
  );
};

export default Sidebar;
