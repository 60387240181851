export const screenSizes = {
  isLarge: "(min-width: 1921px)",
  isBig: "(max-width: 1920px)",
  isMedium: "(max-width: 1024px)",
  isSmall: "(max-width: 760px)",
};

export const colors = {
  black: "#263b47",
  blue: "#036ab5",
  blue2: "#d8e3ec",
  white: "#ffffff",
};

export const TEVIAN_UTM_SOURCE_CODES: { [key: string]: boolean } = {
  343: true, // telegram chanel
};
