import React, { useState } from "react";

type AppProviderProps = { children: React.ReactNode };

type Context = {
  showFeedback: boolean;
  setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessageOpen: boolean;
  setSendMessageOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submenuOpen: boolean;
  setSubmenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submenuIndex: number | null;
  setSubmenuIndex: React.Dispatch<React.SetStateAction<number | null>>;
  utmTags: { [key: string]: any };
  setUtmTags: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  cookiesAreAgreed: boolean;
  setCookiesAreAgreed: React.Dispatch<React.SetStateAction<boolean>>;
  closeSendMessageOnOutsideClick: boolean;
  setCloseSendMessageOnOutsideClick: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const AppStateContext = React.createContext({} as Context);

function AppProvider({ children }: AppProviderProps) {
  const [showFeedback, setShowFeedback] = useState(false);
  const [sendMessageOpen, setSendMessageOpen] = useState(false);
  const [closeSendMessageOnOutsideClick, setCloseSendMessageOnOutsideClick] =
    useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [submenuIndex, setSubmenuIndex] = useState<number | null>(null);
  const [utmTags, setUtmTags] = useState<{ [key: string]: any }>({});
  const [cookiesAreAgreed, setCookiesAreAgreed] = useState(false);
  return (
    <AppStateContext.Provider
      value={{
        showFeedback,
        setShowFeedback,
        sendMessageOpen,
        setSendMessageOpen,
        submenuOpen,
        setSubmenuOpen,
        submenuIndex,
        setSubmenuIndex,
        sidebarOpen,
        setSidebarOpen,
        utmTags,
        setUtmTags,
        cookiesAreAgreed,
        setCookiesAreAgreed,
        closeSendMessageOnOutsideClick,
        setCloseSendMessageOnOutsideClick,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

function useAppProvider() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}
export { AppProvider, useAppProvider };
