import { StaticImageData } from "next/image";

import { Case } from "./cases";
import {
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
  feature9,
  feature10,
  feature11,
  feature12,
  SolutionFeature,
} from "./features";
import { Achivement } from "./products";

import FaceIcon from "../../public/imgs/icons/face_icon.svg?url";
import SolutionImg1 from "../../public/imgs/solutions/solution-01-big.png";
import Preview1 from "../../public/imgs/solutions/solution-01-small.png";
import SolutionImg2 from "../../public/imgs/solutions/solution-02-big.png";
import Preview2 from "../../public/imgs/solutions/solution-02-small.png";
import SolutionImg3 from "../../public/imgs/solutions/solution-03-big.png";
import Preview3 from "../../public/imgs/solutions/solution-03-small.png";
import SolutionImg4 from "../../public/imgs/solutions/solution-04-big.png";
import Preview4 from "../../public/imgs/solutions/solution-04-small.png";

export type Solution = {
  id: number;
  name: string;
  abstractImg: StaticImageData;
  previewImg: StaticImageData;
  features: SolutionFeature[];
  cases: Case[];
  href: string;
  achivements?: Achivement[];
};

const solution1: Solution = {
  id: 1,
  name: "Умный город",
  abstractImg: SolutionImg1,
  previewImg: Preview1,
  features: [feature1, feature2, feature3],
  cases: [],
  href: "smart-city",
};

const solution2: Solution = {
  id: 2,
  name: "Ритейл",
  abstractImg: SolutionImg2,
  previewImg: Preview2,
  features: [feature4, feature5, feature6],
  cases: [],
  href: "retail",
};
const solution3: Solution = {
  id: 3,
  name: "Финансовый сектор",
  abstractImg: SolutionImg3,
  previewImg: Preview3,
  features: [feature7, feature8, feature9],
  cases: [],
  href: "financial-sector",
  achivements: [{ name: "uni_bio_system", imgSrc: FaceIcon }],
};
const solution4: Solution = {
  id: 4,
  name: "Умный офис",
  abstractImg: SolutionImg4,
  previewImg: Preview4,
  features: [feature10, feature11, feature12],
  cases: [],
  href: "smart-office",
};

export const solutions: Solution[] = [
  solution1,
  solution2,
  solution3,
  solution4,
];

const solutions_list: { [key: string]: Solution } = {
  "smart-city": solution1,
  retail: solution2,
  "financial-sector": solution3,
  "smart-office": solution4,
};

export const getSolutionByName = (s_name: string) => {
  return solutions_list[s_name];
};
