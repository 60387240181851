import { ChangeEventHandler, FC } from "react";
import styles from "./TextField.module.scss";
import classNames from "classnames";

export type Props = {
  id: string;
  value?: string;
  label?: string;
  name?: string;
  error?: boolean | string;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  handleBlur?: any;
  addclasses?: string[];
};

const TextField: FC<Props> = ({
  id,
  value,
  label,
  name,
  error,
  handleChange,
  handleBlur,
  addclasses,
}) => {
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const inputClass = classNames([styles["textfield"]], {
    [styles["error"]]: error,
  });

  return (
    <div className={className}>
      <input
        name={name || id}
        id={id}
        value={value}
        required
        className={inputClass}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <span className={styles["bar"]}></span>
      <label className={styles["label"]}>{label || name || id}</label>
    </div>
  );
};

export default TextField;
