import classNames from "classnames";
import { useRouter } from "next/router";

import styles from "./Language.module.scss";

type Props = {
  dark: boolean;
  addclasses?: string[];
};

const Language = ({ dark, addclasses }: Props) => {
  const router = useRouter();
  const onToggleLanguageClick = (newLocale: string) => {
    const { asPath } = router;
    router.push(asPath, asPath, { locale: newLocale });
  };
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {
      [styles["container__dark"]]: dark,
    }
  );
  return (
    <ul className={className}>
      <li
        onClick={() => {
          document.cookie = "NEXT_LOCALE=ru";
          onToggleLanguageClick("ru");
        }}
        className={`${router.locale === "ru" ? styles["selected"] : ""}`}
      >
        <p className="Head">Ru</p>
      </li>
      <li
        onClick={() => {
          document.cookie = "NEXT_LOCALE=en";
          onToggleLanguageClick("en");
        }}
        className={`${router.locale === "en" ? styles["selected"] : ""}`}
      >
        <p className="Head">En</p>
      </li>
    </ul>
  );
};

export default Language;
