import { StaticImageData } from "next/image";

import NoPhoto from "../../public/imgs/img-no-photo.png";
import CleanCity from "../../public/imgs/solution-features/cleanCity.png";
import ComfortCity from "../../public/imgs/solution-features/comfortCity.png";
import Finance1 from "../../public/imgs/solution-features/finance-1.png";
import Finance2 from "../../public/imgs/solution-features/finance-2.png";
import Office1 from "../../public/imgs/solution-features/office-1.png";
import Office2 from "../../public/imgs/solution-features/office-2.png";
import Office3 from "../../public/imgs/solution-features/office-3.png";
import Retail1 from "../../public/imgs/solution-features/retail-1.png";
import Retail2 from "../../public/imgs/solution-features/retail-2.png";
import Retail3 from "../../public/imgs/solution-features/retail-3.png";
import SecureCity from "../../public/imgs/solution-features/secureCity.png";

export type SolutionFeature = {
  title?: string;
  description_title?: string;
  description: string | string[];
  imgSrc: StaticImageData;
};

export const feature1: SolutionFeature = {
  title: "safe_city",
  description: [
    "search_criminality",
    "search_certain_person",
    "search_stolen_cars",
    "search_crowds",
  ],
  imgSrc: SecureCity,
};
export const feature2: SolutionFeature = {
  title: "accessible_city",
  description: ["movement_analysis", "count_passangers"],
  imgSrc: ComfortCity,
};
export const feature3: SolutionFeature = {
  title: "clean_city",
  description: [
    "employers_control",
    "transport_recognition",
    "taxi_paint",
    "garbagetrack_detection",
  ],
  imgSrc: CleanCity,
};
export const feature4: SolutionFeature = {
  title: "improving_service",
  description: [
    "employee_timecontrol",
    "out_of_stock",
    "check_shelves",
    "client_waiting",
    "employee_mask_on",
  ],
  imgSrc: Retail1,
};
export const feature5: SolutionFeature = {
  title: "decipher_behavior",
  description: ["visitor_count", "uniq_visitors", "visitor_time", "vip_client"],
  imgSrc: Retail2,
};
export const feature6: SolutionFeature = {
  title: "preventing_theft",
  description: ["shoplifters", "goods_recognition"],
  imgSrc: Retail3,
};
export const feature7: SolutionFeature = {
  title: "convenient_service",
  description: ["face_pay"],
  imgSrc: Finance1,
};
export const feature8: SolutionFeature = {
  title: "security",
  description: ["person_verification", "internet_banking"],
  imgSrc: Finance2,
};
export const feature9: SolutionFeature = {
  title: "business_optimizations",
  description: ["auto_doc_detection", "atm"],
  imgSrc: NoPhoto,
};
export const feature10: SolutionFeature = {
  title: "biometric_acs",
  description: ["face_recognition", "passby_time"],
  imgSrc: Office1,
};
export const feature11: SolutionFeature = {
  title: "auto_doc_recognition",
  description: ["doc_acceleration", "web_archive", "all_doc_types"],
  imgSrc: Office2,
};
export const feature12: SolutionFeature = {
  title: "employee_time",
  description: ["skud", "employee_leaving", "employee_workspace"],
  imgSrc: Office3,
};
