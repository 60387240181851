import { FC, HTMLProps } from "react";
import styles from "./Burger.module.scss";
import classNames from "classnames";
import { NonBoolean } from "../../../utils/types";

export type Props = {
  opened: NonBoolean;
  addclasses?: string[];
  dark?: NonBoolean;
} & HTMLProps<HTMLDivElement>;

const Burger: FC<Props> = (props) => {
  const { opened, dark, addclasses } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    { [styles["opened"]]: opened, [styles["dark"]]: dark }
  );

  return (
    <div className={className} {...props}>
      <span></span>
    </div>
  );
};

export default Burger;
