import { FC } from "react";
import styles from "./OutlinedButton.module.scss";
import classNames from "classnames";
import Link from "next/link";

export type Props = {
  title: string;
  light?: boolean;
  href?: string;
  newTab?: boolean;
  handleClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  addclasses?: string[];
};

const OutlinedButton: FC<Props> = ({
  title,
  light,
  href,
  newTab,
  type,
  handleClick,
  addclasses,
}) => {
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {
      [styles["light"]]: !!light,
    }
  );

  const component = href ? (
    <Link
      className={styles["title"]}
      href={href}
      target={newTab ? "_blank" : "_self"}
    >
      {title}
    </Link>
  ) : (
    <span className={styles["title"]}>{title}</span>
  );

  return (
    <button type={type} className={className} onClick={handleClick}>
      {component}
    </button>
  );
};

export default OutlinedButton;
