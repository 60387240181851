import { FC, useEffect, useLayoutEffect } from "react";
import styles from "./HoverMenu.module.scss";
import classNames from "classnames";
import GridContainer from "../GridContainer/GridContainer";
import NavLink from "../NavLink/NavLink";
import { gsap } from "gsap";
import { useAppProvider } from "../../utils/appProvider";
import { useTranslation } from "next-i18next";
import { products } from "../../assets/data/products";
import { solutions } from "../../assets/data/solutions";
import { useRouter } from "next/router";

gsap.config({ nullTargetWarn: false });

export type Props = { noShadow?: boolean; addclasses?: string[] };

let hoverMenuIsClosed: boolean | null = null;

const HoverMenu: FC<Props> = ({ noShadow, addclasses }) => {
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    { [styles["no-shadow"]]: noShadow }
  );

  const { submenuIndex, setSubmenuIndex } = useAppProvider();
  const router = useRouter();
  const { t } = useTranslation(["common", "products"]);
  const wrapperRef = `.${styles["wrapper"]}`;
  const containerRef = `.${styles["container"]}`;
  const current_sublinks_ref = `.${styles["sublinks"]}:nth-of-type(${
    submenuIndex || 0
  })`;
  const prev_sublinks_ref = `.${styles["sublinks"]}:not(:nth-of-type(${
    submenuIndex || 0
  }))`;

  const animateSubmenuChange = () => {
    const tl = gsap.timeline();
    tl.set(wrapperRef, { zIndex: 1 }); // hidden initially
    // open
    tl.set(containerRef, { height: "auto" });
    // hide opened sublist
    tl.to(prev_sublinks_ref, { opacity: 0, duration: 0.1 });
    // open selected sublist
    tl.set(current_sublinks_ref, { opacity: 1 });
    tl.to(current_sublinks_ref, { height: "auto", duration: 0.1 });
    // close opened sublinks
    tl.to(prev_sublinks_ref, { height: 0 });
    // slide down
    tl.to(wrapperRef, { y: "calc(32px / 100% * 100vw)" });
    return tl;
  };

  const animateHoverMenuClose = () => {
    const tl = gsap.timeline();
    tl.set(prev_sublinks_ref, { opacity: 0 });
    tl.to(prev_sublinks_ref, { height: 0 });
    // close & slide up
    tl.to(containerRef, { height: 0 }, "<");
    tl.to(wrapperRef, { y: -200 }, "<");
  };

  const animateHoverMenuOpen = () => {
    const tl = gsap.timeline();
    tl.set(wrapperRef, { zIndex: 1, opacity: 1 }); // hidden initially
    // hide opened sublist
    tl.set(prev_sublinks_ref, { opacity: 0 });
    // open
    tl.set(containerRef, { height: "auto" });
    // open invisible selected sublist
    tl.to(current_sublinks_ref, { height: "auto", duration: 0.3 });
    // slide down
    tl.to(wrapperRef, { y: "calc(32px / 100% * 100vw)" }, "<");
    // make selected sublist visible
    tl.to(current_sublinks_ref, { opacity: 1 }, "-=0.2");
    tl.eventCallback("onComplete", () => {
      hoverMenuIsClosed = false;
    });
    tl.to(prev_sublinks_ref, { height: 0 }, "-=0.5");
    return tl;
  };

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (submenuIndex !== null) {
        if (hoverMenuIsClosed) {
          animateHoverMenuOpen();
        } else {
          animateSubmenuChange();
        }
      } else {
        animateHoverMenuClose();
        hoverMenuIsClosed = true;
      }
    });
    return () => {
      ctx.kill();
    };
  }, [submenuIndex]);

  useEffect(() => {
    return () => {
      setSubmenuIndex(null);
      hoverMenuIsClosed = true;
    };
  }, [router]);

  return (
    <div className={styles["wrapper"]}>
      <GridContainer addclasses={[className]}>
        <div className={styles["links"]}>
          <NavLink title={t("common:products")} href="#" arrow />
          <NavLink title={t("common:solutions")} href="#" arrow />
          <NavLink title={t("common:for_partners")} href="#" />
          <NavLink title={t("common:about_us")} href="#" arrow />
          <NavLink title={t("common:contacts")} href="#" />
          <div className={styles["sublinks"]}>
            {products.map((product) => (
              <NavLink
                href={`/product/${product.href}`}
                key={product.href}
                title={t(`products:name.${product.href}`)}
              />
            ))}
          </div>
          <div className={styles["sublinks"]}>
            {solutions.map((solution) => (
              <NavLink
                href={`/solution/${solution.href}`}
                key={solution.href}
                title={t(`solutions:name.${solution.href}`)}
              />
            ))}
            <NavLink href={"/customer"} title={t("common:customer")} />
          </div>
          <div className={styles["sublinks"]}></div>
          <div className={styles["sublinks"]}>
            <NavLink title={t("common:career")} href="/careers" />
          </div>
          <div className={styles["sublinks"]}></div>
        </div>
      </GridContainer>
    </div>
  );
};

export default HoverMenu;
