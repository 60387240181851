import { StaticImageData } from "next/image";

import NoPhoto from "../../public/imgs/img-no-photo.png";
import CrowdEstimationSdkImg from "../../public/imgs/sdk/crowdestimation-sdk.png";
import DocSdkImg from "../../public/imgs/sdk/doc-sdk.png";
import FaceSdkImg from "../../public/imgs/sdk/face-sdk.png";
import FaceCaptureSdkImg from "../../public/imgs/sdk/facecapture-sdk.png";
import FaceCloudSdkImg from "../../public/imgs/sdk/facecloud-sdk.png";
import LprSdkImg from "../../public/imgs/sdk/lpr-sdk.png";
import PersonDetectionSdkImg from "../../public/imgs/sdk/persondetection-sdk.png";
import PosAnalytic from "../../public/imgs/sdk/pos_analytics.png";
import ShelveRecognition from "../../public/imgs/sdk/shelve_recognition.png";
import VehicleRecognitionSdkImg from "../../public/imgs/sdk/vehiclerecognition-sdk.png";

export type SDK = {
  title?: string;
  description_title?: string;
  description: string | string[];
  imgSrc: StaticImageData;
};

const FaceSDK: SDK = {
  title: "TEVIAN FACE\u00A0SDK",
  description: ["cross_platform_library", "max_effective"],
  imgSrc: FaceSdkImg,
};
const FaceCaptureSDK: SDK = {
  title: "TEVIAN FACE\u00A0CAPTURE",
  description: ["facerecognition_rtsp", "convinient_webapi"],
  imgSrc: FaceCaptureSdkImg,
};
const FaceCloudSDK: SDK = {
  title: "TEVIAN FACE\u00A0CLOUD",
  description: "face_rec_by_image",
  imgSrc: FaceCloudSdkImg,
};
const PersonDetectSDK: SDK = {
  title: "TEVIAN PERSON DETECTION\u00A0SDK",
  description: "people_highlight",
  imgSrc: PersonDetectionSdkImg,
};
const CrowdEstimationSDK: SDK = {
  title: "TEVIAN CROWD ESTIMATION",
  description: "quantity_assessment",
  imgSrc: CrowdEstimationSdkImg,
};
const LprSDK: SDK = {
  title: "TEVIAN LPR\u00A0SDK",
  description: ["transport_analyze", "video_management_system"],
  imgSrc: LprSdkImg,
};
const VehicleRecognitionSDK: SDK = {
  title: "Tevian Vehicle Recognition",
  description: "vehicle_webservice",
  imgSrc: VehicleRecognitionSdkImg,
};
const DocSDK: SDK = {
  title: "TEVIAN DOC\u00A0SDK",
  description: ["doc_rec_library", "doc_rec_webservice"],
  imgSrc: DocSdkImg,
};
const AbandonedObjectsDetector: SDK = {
  title: "Tevian Abandoned Objects Detector",
  description: "left_items_library",
  imgSrc: NoPhoto,
};
const PeopleTrackingSDK: SDK = {
  title: "TEVIAN PERSON TRACKING\u00A0SDK",
  description: "people_tracking_library",
  imgSrc: NoPhoto,
};
const PeopleCounter: SDK = {
  title: "TEVIAN PEOPLE COUNTER",
  description: "virtual_signal_line",
  imgSrc: NoPhoto,
};
const ShelfRecognition: SDK = {
  title: "Tevian Shelf Recognition",
  description: "shelves_webservice",
  imgSrc: ShelveRecognition,
};
const PosAnalytics: SDK = {
  title: "Tevian POS\u00A0Analytics",
  description: "scanned_goods_library",
  imgSrc: PosAnalytic,
};

export const sdk_list = {
  FaceSDK,
  FaceCaptureSDK,
  FaceCloudSDK,
  PersonDetectSDK,
  CrowdEstimationSDK,
  LprSDK,
  VehicleRecognitionSDK,
  DocSDK,
  AbandonedObjectsDetector,
  PeopleTrackingSDK,
  PeopleCounter,
  ShelfRecognition,
  PosAnalytics,
};
