import { useEffect } from "react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";

import Navigation from "../components/Navigation";
import SendMessage from "../components/Navigation/SendMessage";
import Sidebar from "../components/Navigation/Sidebar/Sidebar";
import { AppProvider } from "../utils/appProvider";
import { isMacintosh } from "../utils/functions";

import "../styles/globals.scss";

function App({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  useEffect(() => {
    // do not customize scrollbar on MacOS
    if (isMacintosh()) {
      document.querySelector("html")?.classList.add("macos");
    }
    // hide unstyled version on load
    document.querySelector("html")?.classList.remove("scrollbar__hidden");
  }, []);
  return (
    <AppProvider>
      <Script id="yandex-metric">
        {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                  m[i].l=1*new Date();
                  for (var j = 0; j < document.scripts.length; j++) {
                    if (document.scripts[j].src === r) { return; }
                  }
                  k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                  ym(${process.env.NEXT_PUBLIC_YANDEX_METRIC_ID}, "init", {
                    defer: true,
                        clickmap:true,
                        trackLinks:true,
                        accurateTrackBounce:true,
                        webvisor:true
                  });`}
      </Script>
      <Sidebar />
      <Navigation dark={pathname == "/"} />
      <SendMessage />
      <Component {...pageProps} />
    </AppProvider>
  );
}

export default appWithTranslation(App);
