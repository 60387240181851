export const adjustHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};

export function support_format_webp(document: Document) {
  const elem = document.createElement("canvas");
  if (!!(elem.getContext && elem.getContext("2d"))) {
    const dataUrl = elem.toDataURL("image/webp");
    const supports = dataUrl.indexOf("data:image/webp") == 0;
    // https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = dataUrl;
    return supports;
  } else {
    return false;
  }
}

export function isMacintosh() {
  return navigator.platform.indexOf("Mac") > -1;
}

export const isLocalStorageEnabled = () => {
  try {
    const key = `__storage__test`;
    window.localStorage.setItem(key, "");
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const isSessionStorageEnabled = () => {
  try {
    const key = `__storage__test`;
    window.sessionStorage.setItem(key, "");
    window.sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};
