import { FC, HTMLProps, useLayoutEffect, useRef } from "react";
import styles from "./Submenu.module.scss";
import classNames from "classnames";
import Arrow from "./arrow.svg";
import { gsap } from "gsap";
import { NonBoolean } from "../../../utils/types";

gsap.config({ nullTargetWarn: false });

export type Props = {
  title: string;
  opened: NonBoolean;
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const Submenu: FC<Props> = (props) => {
  const root = useRef(null);
  const { title, addclasses, opened, children } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const listClass = classNames([styles["list"]], {
    [styles["closed"]]: !opened,
  });

  useLayoutEffect(() => {
    const listSelector = `.${styles["list"]}`;
    const arrowSelector = `.${styles["arrow"]}`;
    const ctx = gsap.context(() => {
      if (opened) {
        gsap.to(listSelector, { height: "auto" });
        gsap.to(arrowSelector, { rotate: 180 });
      } else {
        gsap.to(listSelector, { height: 0 });
        gsap.to(arrowSelector, { rotate: 0 });
      }
    }, root);
    return () => ctx.kill();
  }, [opened]);

  return (
    <div ref={root} className={className} {...props}>
      <div className={styles["title"]}>
        {title}
        <Arrow className={styles["arrow"]} />
      </div>
      <div className={listClass}>{children}</div>
    </div>
  );
};

export default Submenu;
