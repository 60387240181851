import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./Navigation.module.scss";
import classNames from "classnames";
import Logo from "../Logo";
import GridContainer from "../GridContainer/GridContainer";
import Language from "./Language/Language";
import OutlinedButton from "../Buttons/OutlinedButton/OutlinedButton";
import NavLink from "../NavLink/NavLink";
import HoverMenu from "../HoverMenu/HoverMenu";
import { useAppProvider } from "../../utils/appProvider";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Burger from "./Burger/Burger";
import { gsap } from "gsap";

gsap.config({ nullTargetWarn: false });

export type Props = { dark?: boolean; addclasses?: string[] };

let prevScroll = 0;

const Navigation: FC<Props> = ({ dark, addclasses }) => {
  const {
    submenuIndex,
    sendMessageOpen,
    setSubmenuIndex,
    sidebarOpen,
    setSidebarOpen,
    setSendMessageOpen,
  } = useAppProvider();

  const router = useRouter();
  const statusBarColor = router.pathname === "/" ? "#263b47" : "#fff";
  const { t } = useTranslation("common");
  const navigationIsDark = dark ? !sidebarOpen && !submenuIndex : false;
  const tl = useRef<gsap.core.Timeline>();
  const [scrollDown, setScrollDown] = useState(false);
  const navRef = `.${styles["wrapper"]}`;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const wrapperClass = classNames([styles["wrapper"]], {
    [styles["wrapper__dark"]]: dark,
    [styles["hover-menu"]]: submenuIndex || sidebarOpen,
  });

  const handleScroll = () => {
    const { scrollTop } = document.documentElement;
    const detectScroll = Math.abs(prevScroll - scrollTop) > 40;
    if (scrollTop <= 0 || !detectScroll) return; // when page bounces on iOS devices
    if (scrollTop > prevScroll) {
      setScrollDown(true);
    } else if (scrollTop <= prevScroll) {
      setScrollDown(false);
    }
    prevScroll = scrollTop;
  };

  const handleHover = (id: number | null) => {
    setSubmenuIndex(id);
  };

  const handleLogoClick = () => {
    router.push("/").then(() => {
      setSidebarOpen(false);
      document.querySelector("html")!.style.overflowY = "overlay";
    });
  };

  const handleBurgerClick = () => {
    setSidebarOpen((prev) => {
      if (prev) {
        document.querySelector("html")!.style.overflowY = "overlay";
        setTimeout(() => {
          document
            .querySelector('meta[name="theme-color"]')
            ?.setAttribute("content", statusBarColor);
        }, 250);
      } else {
        document.querySelector("html")!.style.overflowY = "hidden";
        document
          .querySelector('meta[name="theme-color"]')
          ?.setAttribute("content", "#fff");
      }
      return !prev;
    });
  };

  const handleSendMessageClick = () => {
    setSendMessageOpen((prev) => {
      if (prev) {
        document.querySelector("html")!.style.overflowY = "overlay";
      } else {
        document.querySelector("html")!.style.overflowY = "hidden";
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (router.pathname === "/") {
      document.body.style.background = "#263b47";
    } else {
      document.body.style.background = "#ffffff";
    }
    if (document.body.offsetWidth > 600 || sidebarOpen) return;
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarOpen]);

  useLayoutEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current.to(navRef, { y: -100 });
    return () => {
      tl.current?.kill();
    };
  }, [router, navRef]);

  useLayoutEffect(() => {
    scrollDown ? tl.current?.play() : tl.current?.reverse();
  }, [scrollDown]);

  return (
    <div style={{ position: "relative", zIndex: 200 }}>
      <div className={wrapperClass}>
        <GridContainer addclasses={[className]}>
          <Logo addclasses={[styles["logo"]]} handleClick={handleLogoClick} />
          <div className={styles["links"]}>
            <NavLink
              title={t("products")}
              href="/product"
              handleMouseEnter={() => handleHover(1)}
              selected={submenuIndex === 1}
              arrow
            />
            <NavLink
              title={t("solutions")}
              href="/solution"
              handleMouseEnter={() => handleHover(2)}
              selected={submenuIndex === 2}
              arrow
            />
            <NavLink
              title={t("for_partners")}
              href="/partners"
              handleMouseEnter={() => handleHover(null)}
            />
            <NavLink
              title={t("about_us")}
              href="/about"
              handleMouseEnter={() => handleHover(4)}
              selected={submenuIndex === 4}
              arrow
            />
            <NavLink
              title={t("contacts")}
              href="/contacts"
              handleMouseEnter={() => handleHover(null)}
            />
          </div>
          <Language addclasses={[styles["language"]]} dark />
          <OutlinedButton
            title={t("discuss_issue")}
            addclasses={[styles["discuss-button"]]}
            light={navigationIsDark && !sidebarOpen}
            handleClick={handleSendMessageClick}
          />
          <Burger
            opened={sidebarOpen ? 1 : 0}
            onClick={handleBurgerClick}
            addclasses={[styles["burger"]]}
            dark={!dark || sidebarOpen ? 1 : 0}
          />
        </GridContainer>
      </div>
      <HoverMenu addclasses={[styles["hover"]]} noShadow={dark} />
    </div>
  );
};

export default Navigation;
