import { FC, ReactNode } from "react";
import styles from "./GridContainer.module.scss";
import classNames from "classnames";

export enum GridType {
  Default,
  Couple,
}

export type Props = {
  type?: GridType;
  addclasses?: string[];
} & React.HTMLProps<HTMLDivElement>;

const GridContainer: FC<Props> = (props) => {
  const { children, type, addclasses } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {
      [styles["couple"]]: type === GridType.Couple,
    }
  );
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default GridContainer;
