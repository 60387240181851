import { ChangeEventHandler, useRef } from "react";

import styles from "./Textarea.module.scss";
import classNames from "classnames";

export type Props = {
  id: string;
  value?: string;
  label?: string;
  name?: string;
  error?: boolean | string;
  handleChange?: ChangeEventHandler<HTMLTextAreaElement>;
  handleBlur?: any;
  addclasses?: string[];
};

const Textarea = ({
  id,
  value,
  label,
  name,
  error,
  handleChange,
  handleBlur,
  addclasses,
}: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const inputClass = classNames([styles["textarea"]], {
    [styles["error"]]: error,
  });

  return (
    <div className={className}>
      <textarea
        ref={textareaRef}
        name={name}
        id={id}
        value={value}
        required
        className={inputClass}
        onChange={(e) => {
          const textarea = textareaRef.current!;
          textarea.style.height = "auto";
          textarea.style.height = textarea.scrollHeight + "px";
          if (textarea.scrollHeight < 220) {
            textarea.classList.add(styles["hide__scrollbar"]);
          } else {
            textarea.classList.remove(styles["hide__scrollbar"]);
          }
          handleChange!(e);
        }}
        onBlur={handleBlur}
      ></textarea>
      <span className={styles["bar"]}></span>
      <label className={styles["label"]}>{label}</label>
    </div>
  );
};

export default Textarea;
